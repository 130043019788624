<template>
  <div class="download-page">
    <div class="book_info_header">
      <topHeader></topHeader>
    </div>

    <div class="main">
      <div class="center">
        <div class="right-box">
          <div class="logo-box">
            <img
              src="../../assets/images/downloadImg/logo.png"
              class="logo"
              alt=""
            />
            <img
              src="../../assets/images/downloadImg/title.png"
              class="title"
              alt=""
            />
          </div>
          <div class="erweima-box">
            <div class="erweima">
              <p>扫描二维码马上下载</p>
              <img :src="imgPath" alt="" />
            </div>
            <div class="btn-box">
              <div class="ios-btn btn" @mouseover="iosOver()" @click="iosdw()">
                <img src="../../assets/images/downloadImg/iphone.png" alt="" />
                <div class="btn-text">
                  <p>立即下载</p>
                  <p>Iphone版 V1.2.1</p>
                </div>
              </div>
              <div class="ad-btn btn" @mouseover="adOver()" @click="addw()">
                <img src="../../assets/images/downloadImg/ad.png" alt="" />
                <div class="btn-text">
                  <p>立即下载</p>
                  <p>Android版 V1.2.1</p>
                </div>
              </div>
            </div>
          </div>
          <div class="text">
            <span class="left"></span>
            关注我们
            <span class="right"></span>
          </div>
          <div class="chat-box">
            <div class="box">
              <img src="../../assets/images/downloadImg/wb.png" alt="" />
              <div>
                <p>官方微博</p>
                <p>@鲸云轻小说</p>
              </div>
            </div>
            <div class="box">
              <img src="../../assets/images/downloadImg/qq.png" alt="" />
              <div>
                <p>bug反馈群</p>
                <p>ios 915037401</p>
                <p>安卓 951715891</p>
              </div>
            </div>
            <div class="box">
              <img src="../../assets/images/downloadImg/wx.png" alt="" />
              <div>
                <p>微信公众号</p>
                <p
                  @mouseenter="gongShow = true"
                  @mouseout="gongShow = false"
                  class="gong"
                >
                  订阅号：鲸云轻阅读
                </p>
                <p
                  @mouseenter="xiaoShow = true"
                  @mouseout="xiaoShow = false"
                  class="xiao"
                >
                  小程序：鲸云读书
                </p>
                <div class="ma" v-show="gongShow">
                  <img src="../../assets/images/downloadImg/gong.png" alt="" />
                  <div class="sanjiao"></div>
                </div>
                <div class="ma2" v-show="xiaoShow">
                  <img src="../../assets/images/downloadImg/xiao.png" alt="" />
                  <div class="sanjiao"></div>
                </div>
              </div>
            </div>
            <div class="box">
              <img src="../../assets/images/downloadImg/qq.png" alt="" />
              <div>
                <p>用户交流群</p>
                <p>群号 839016927</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="jy-main-bottom">
      <mainbottom :options="options_bottom"></mainbottom>
    </div>
    <div class="bottom">
      <div class="btottom-center">
        <ul>
          <li>
            <img src="../../assets/images/police.png" alt="" /><span
              >公安网备</span
            >
          </li>
          <li></li>
          <li><span>网文许可证</span></li>
          <li></li>
          <li><span>电信增值业务许可证</span></li>
          <li></li>
          <li><span>ICP备案</span></li>
        </ul>
        <div>
          <p>川公网安备 51019002002293号</p>
          |
          <p>川网文 [2020]2152-468号</p>
          |
          <p>增值电信业务经营许可证川 B2-20201265</p>
          |
          <p class="bei" @click="goBeian()">蜀ICP备18031577号-2</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topHeader from "@/components/main/top-header.vue";
import mainbottom from ".././main/main-bottom";
import { home_two } from "@/https/home.js";

export default {
  components: {
    topHeader,
    mainbottom,
  },
  data() {
    return {
      options_bottom: {
        title: [],
        footer_photo: [],
      },
      imgPath: require("../../assets/images/downloadImg/ios.png"),
      gongShow: false,
      xiaoShow: false,
    };
  },
  created() {
    this.load_data_two();
  },
  mounted() {
    // document.documentElement.scrollTop=0
  },
  methods: {
    load_data_two() {
      home_two().then((res) => {
        // 底部信息
        this.options_bottom.title = [
          res.data.title1,
          res.data.title2,
          res.data.title3,
          res.data.title4,
        ];
        this.options_bottom.footer_photo = res.data.footer_photo;
        this.options_bottom.footer_photo = this.options_bottom.footer_photo.splice(
          0,
          4
        );
      });
    },
    iosOver() {
      this.imgPath = require("../../assets/images/downloadImg/ios.png");
    },
    adOver() {
      this.imgPath = require("../../assets/images/downloadImg/adma.png");
    },
    iosdw() {
      console.log(1);
    },
    addw() {
      console.log(2);
    },
    goBeian() {
      window.open("https://beian.miit.gov.cn");
    },
  },
};
</script>

<style lang="scss" scoped>
.download-page {
  background-color: #fafcff;
  // background-color: rgba(237, 231, 218, 1);
  /deep/.book_info_header {
    z-index: 200;
    width: 100%;
    position: relative;

    // background-color: rgba(248, 243, 233, 1);
    background-color: #ffffff;
    box-shadow: 0 5px 5px -5px rgba(153, 153, 153, 0.2);
    .top_header {
      // background-color: rgba(248, 243, 233, 1);
      background-color: #ffffff;
      height: 56px;
      .el-input__inner {
        height: 36px !important;
        border-radius: 50px !important;
      }
      .input_arr {
        height: 36px;
        line-height: 36px;
      }
      .seach {
        width: 45px;
        background: #eeeeee;
        color: #999999;
        font-size: 20px;
        height: 36px;
        border-radius: 0 50px 50px 0 !important;
      }
    }
  }
}

.main {
  width: 100%;
  max-height: 917px;
  background: url(https://images.weserv.nl/?url=https://jyacgpic.jyacg.com/app-pic/app-bgp.png)
    no-repeat center center;
  background-size: cover;
  object-fit: cover;
  background-color: linear-gradient(162deg, #65d4ff 0%, #9da9af 100%);
  .center {
    width: 1200px;
    height: 917px;
    margin: auto;
    .right-box {
      float: right;
      padding-top: 95px;
      height: 100%;
      width: 536px;
      box-sizing: border-box;

      .logo-box {
        width: 100%;
        height: 114px;
        display: flex;
        justify-content: space-between;
        .logo {
          width: 114px;
          height: 114px;
          box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.3);
          border-radius: 10px;
        }
        .title {
          width: 412px;
          height: 114px;
          // box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.3);
        }
      }
      .erweima-box {
        width: 498px;
        height: 233px;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        .erweima {
          width: 218px;
          height: 100%;
          background: #6c96fc;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.3);
          p {
            height: 26px;
            font-size: 20px;
            line-height: 26px;
            color: #ffffff;
            margin-top: 13px;
            margin-bottom: 11px;
          }
          img {
            width: 166px;
            height: 166px;
          }
        }
        .btn-box {
          width: 240px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .btn {
            width: 240px;
            height: 100px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.3);
            img {
              width: 56px;
              height: 68px;
              margin-right: 14px;
            }
            .btn-text {
              p:nth-of-type(1) {
                font-size: 25px;
                line-height: 33px;
                color: #ffffff;
              }
              p:nth-of-type(2) {
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
              }
            }
          }
          .ios-btn {
            background: #abcd00;
          }
          .ad-btn {
            background: #f9aa4d;
          }
        }
      }
      .text {
        margin-top: 60px;
        height: 33px;
        font-size: 25px;
        line-height: 33px;
        color: #000000;
        display: flex;
        align-items: center;
        span {
          width: 33px;
          height: 0px;
          border: 1px solid #000000;
        }
        .left {
          margin-right: 10px;
        }
        .right {
          margin-left: 10px;
        }
      }
      .chat-box {
        width: 100%;
        height: 230px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
        .box {
          width: 50%;
          height: 50%;
          display: flex;
          > img {
            width: 46px;
            height: 46px;
            margin-right: 20px;
            box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.3);
            border-radius: 10px;
          }
          > div {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            position: relative;
            p {
              height: 24px;
              font-size: 18px;
              line-height: 26px;
              color: #333333;
              margin-bottom: 5px;
              // pointer-events: none;
            }
            p:nth-of-type(1) {
              height: 24px;
              font-size: 18px;
              font-weight: bold;
              line-height: 26px;
              color: #333333;
              margin-bottom: 10px;
            }
            .gong {
              text-decoration: underline;
              cursor: pointer;
            }
            .xiao {
              text-decoration: underline;
              cursor: pointer;
            }
            .ma {
              width: 180px;
              height: 180px;
              position: absolute;
              top: -160px;
              left: 0;
              img {
                margin: auto;
              }
              div {
                width: 0;
                height: 0;
                border: 20px solid;
                border-color: #fff transparent transparent transparent;
                position: absolute;
                top: 175px;
                left: 70px;
              }
            }
            .ma2 {
              width: 180px;
              height: 180px;
              top: -130px;
              left: 0;
              position: absolute;
              img {
                margin: auto;
              }
              div {
                width: 0;
                height: 0;
                border: 20px solid;
                border-color: #fff transparent transparent transparent;
                position: absolute;
                top: 175px;
                left: 70px;
              }
            }
          }
        }
      }
    }
  }
}

.jy-main-bottom {
  padding-bottom: 12px;
  width: 100%;
  background: #29333c;
}

li {
  list-style: none;
}

.bottom {
  background: #29333c;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
}

.btottom-center {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    width: 850px;
    display: flex;
    justify-content: space-around;
    li {
      list-style: none;
      height: 19px;
      line-height: 19px;
      font-size: 14px;

      color: #ffffff;
    }
    li:nth-of-type(1) {
      display: flex;
      align-items: center;
    }
  }
  div {
    width: 850px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: #999999;
    margin-top: 11px;
    margin-bottom: 14px;
    .bei {
      cursor: pointer;
    }
  }
}
</style>
